import { API_ENQUIRY, API_METHOD_POST } from '@/constants/apiConstants';
import { AddEnquiryNoteData } from '@/redux/thunk/enquiryNoteThunk';
import { apiCall } from '@/utility/api/apiUtils';
import { generateHash } from '@/utility/auth/generateHashValue';
import { Method } from 'axios';

export const AddEnquiryNote = async (enquiryNoteValues: AddEnquiryNoteData) => {
  const method: Method = API_METHOD_POST;
  const url = API_ENQUIRY + '/' + enquiryNoteValues.enquiryUuid + '/notes';
  const data = {
    note: enquiryNoteValues.note,
    attachment: enquiryNoteValues.attachment,
  };

  const jsonString = JSON.stringify(data);
  const additionalHeaders = { hash: generateHash(jsonString) };

  try {
    const response = await apiCall({
      method,
      url,
      data,
      headers: additionalHeaders,
    });
    return response;
  } catch (error) {
    throw error;
  }
};
