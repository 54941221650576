import { API_CUSTOMERS, API_METHOD_POST } from '@/constants/apiConstants';
import { AddCustomerNoteData } from '@/redux/thunk/customerNoteThunk';
import { apiCall } from '@/utility/api/apiUtils';
import { generateHash } from '@/utility/auth/generateHashValue';
import { Method } from 'axios';

export const CreateCustomerNote = async (
  newCustomerData: AddCustomerNoteData,
) => {
  const method: Method = API_METHOD_POST;
  const url = API_CUSTOMERS + '/' + newCustomerData.customerUuid + '/notes';
  const data = {
    note: newCustomerData.note,
    attachment: newCustomerData.attachment,
  };

  const jsonString = JSON.stringify(data);
  const additionalHeaders = { hash: generateHash(jsonString) };

  try {
    const response = await apiCall({
      method,
      url,
      data,
      headers: additionalHeaders,
    });
    return response;
  } catch (error) {
    throw error;
  }
};
