// api/register.ts
import { Method } from 'axios';
import { apiCall } from '@/utility/api/apiUtils';
import {
  API_GET_PURCHASE_ORDERS,
  API_METHOD_GET,
} from '@/constants/apiConstants';

export const SearchPurchaseOrdersData = async (
  searchTerm: string,
  pageSize?: string,
  pagination?: number,
  selectedStatus?: string,
  sortBy?: string | null,
  sortOrder?: string | null,
) => {
  const method: Method = API_METHOD_GET;
  let url =
    API_GET_PURCHASE_ORDERS +
    `?search=${searchTerm}` +
    (pageSize && `&p-size=${pageSize}`) +
    (pagination && `&p=${pagination}`);
  if (selectedStatus) {
    url += `&f-status=${encodeURIComponent(`${selectedStatus}`)}`;
  }

  if (sortBy) {
    url += `&sort-by=${encodeURIComponent(`${sortBy}`)}`;
  }

  if (sortOrder) {
    url += `&sort-order=${encodeURIComponent(`${sortOrder}`)}`;
  }

  try {
    const response = await apiCall({
      method,
      url,
    });
    return response; // Return the response data for further use
  } catch (error) {
    throw error; // Re-throw the error to be handled by the caller
  }
};
