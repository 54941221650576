import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import { Job, JobAll, JobNotes, Pagination, SingleJob } from '@/helpers/types';
import { GetJobAllData } from '@/api/job/getJobAll';
import { GetJobsData } from '@/api/job/getJobs';
import { SearchJobsData } from '@/api/job/searchJobs';
import { toast } from 'react-toastify';
import { DeleteJobData } from '@/api/job/deleteJob';
import { getJobDetailsByUuid } from '@/api/job/getJobDetailsByUuid';
import { CreateJob } from '@/api/job/createJob';
import { JobDuplicateData, JobFormData } from '@/helpers/types/jobTypes';
import { AddJobNoteData } from '@/api/job/addJobNote';
import { UpdateJobNoteData } from '@/api/job/updateJobNote';
import { GetJobNotesData } from '@/api/job/getJobNotes';
import { QuoteFormData } from '@/helpers/types/quoteTypes';
import { UpdateJob } from '@/api/job/updateJob';
import { JobSendEmail } from '@/api/job/jobSendEmail';
import { DuplicateJob } from '@/api/job/duplicateJob';
import { CreateJobSignature } from '@/api/job/createJobSignature';
import { UploadImageToJob } from '@/api/job/uploadImageToJob';
import { uploadHealthAndSaftyForm } from '@/api/job/uploadHealthAndSaftyForm';
import { DeleteJobPhotoData } from '@/api/job/deleteJobPhoto';
import { UpdateJobStatus } from '@/api/job/updateJobStatus';
import { sendBroadcast } from '@/utility/redux/broadcastChannel';
import { DeleteJobNoteData } from '@/api/job/deleteJobNote';

// Define the type for the thunk's return value
type FetchJobAllResponse = {
  data: JobAll[];
};

type FetchJobsResponse = {
  data: Job[];
  pagination: Pagination;
};

type SearchJobsArgs = {
  searchTerm: string;
  pageSize: string;
  pagination: number;
  selectedStatus?: string;
  selectedType?: string;
  startDate?: string | null;
  endDate?: string | null;
  selectedCustomer?: string;
  sortBy?: string | null;
  sortOrder?: string | null;
};

type FetchJobByUuidResponse = {
  data: SingleJob;
};

type AddJobNoteResponse = {
  status: string;
  data: {
    uuid: string;
    note: string;
    attachment: null | string;
  };
  message: string;
};

interface CreateJobResponse {
  message(message: any): unknown;

  data: any; // Adjust based on your API's response structure
}

interface UpdateJobResponse {
  message(message: any): unknown;

  data: any; // Adjust based on your API's response structure
}

type JobSendEmailResponse = {
  // data
  message: string;
};

interface CreateJobArgs extends JobFormData {}

interface CreateJobSignatureArgs {
  uuid: string;
  payload: {
    signature: string;
    send_copy_to_customer?: boolean;
  };
}

interface UpdateJobArgs extends QuoteFormData {}
interface UpdateJobStatusArgs {
  selectedJob: string;
  selectedStatus: string;
}

type updateJobNoteArgs = {
  uuid: string;
  note: string;
  attachment: string;
  jobUuid: string;
  created_at: string;
};

type FetchJobsError = string | SerializedError;
type UpdateJobError = {
  message: string;
  messages?: any; // Add any or a specific type for messages if needed
};

type DeleteJobsArgs = string;
type DeleteJobPhotoArgs = {
  jobUuid: string;
  jobPhotoUuid: string;
};
type FetchJobByUuidArgs = string;
type AddJobNoteArgs = {
  uuid: string;
  note: string;
  attachment: string;
};

type FetchJobNoteResponse = {
  data: JobNotes[];
};
type FetchJobNotesByUuidArgs = string;
type JobSendEmailArgs = string;
type DuplicateJobArgs = JobDuplicateData;

type UploadImageToAJobSArgs = {
  payload: {
    photos: object[];
  };
  uuid: string;
};
type UploadHealthSaftyArgs = {
  payload: {
    attachments: object[];
  };
  uuid: string;
};

type DeleteJobNoteResponse = {
  status: string;
  message: string;
};

type DeleteJobNoteArgs = {
  jobUuid: string;
  noteUuid: string;
};

export const fetchJobAll = createAsyncThunk<
  FetchJobAllResponse, // Return type of the payload creator
  void, // Argument type of the payload creator
  { rejectValue: FetchJobsError }
>('jobs/fetchJobAll', async (_, { rejectWithValue }) => {
  try {
    const response = await GetJobAllData();

    return response;
  } catch (error) {
    // Type guard to narrow down error to Error type
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
    // Fallback for unknown error types
    return rejectWithValue('An unknown error occurred');
  }
});

export const fetchJobs = createAsyncThunk<
  FetchJobsResponse, // Return type of the payload creator
  void, // Argument type of the payload creator
  {
    rejectValue: FetchJobsError; // Type for rejected value
  }
>('jobs/fetchJobs', async (_, { rejectWithValue }) => {
  try {
    const response = await GetJobsData();
    const data: FetchJobsResponse = response.data;
    return { data: data.data, pagination: data.pagination }; // Return formatted data
  } catch (error) {
    // Type guard to narrow down error to Error type
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
    // Fallback for unknown error types
    return rejectWithValue('An unknown error occurred');
  }
});

export const searchJobs = createAsyncThunk<
  FetchJobsResponse,
  SearchJobsArgs, // Updated to use the new argument structure
  {
    rejectValue: FetchJobsError;
  }
>(
  'jobs/searchJobs',
  async (
    {
      searchTerm,
      pageSize,
      pagination,
      selectedStatus,
      selectedType,
      startDate,
      endDate,
      selectedCustomer,
      sortBy,
      sortOrder,
    },
    { rejectWithValue },
  ) => {
    // Destructure the argument
    try {
      const response = await SearchJobsData(
        searchTerm,
        pageSize,
        pagination,
        selectedStatus,
        selectedType,
        startDate,
        endDate,
        selectedCustomer,
        sortBy,
        sortOrder,
      ); // Pass both parameters
      const data: FetchJobsResponse = response.data;
      return { data: data.data, pagination: data.pagination }; // Return formatted data
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('An unknown error occurred');
    }
  },
);

export const deleteJob = createAsyncThunk<
  void, // Return type of the payload creator
  DeleteJobsArgs, // Argument type of the payload creator
  {
    rejectValue: FetchJobsError; // Type for rejected value
  }
>('jobs/deleteJob', async (jobUuId: string, { dispatch, rejectWithValue }) => {
  try {
    // Call the API to delete the team member
    await DeleteJobData(jobUuId);
    toast.success('Job deleted successfully!');
    // After successful deletion, dispatch fetchTeamMembers to refresh the list
    dispatch(fetchJobs());

    // ✅ Broadcast event
    sendBroadcast({ action: 'JOB_DELETED' });
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
    return rejectWithValue('An unknown error occurred');
  }
});

export const fetchJobByUuid = createAsyncThunk<
  FetchJobByUuidResponse, // Return type of the payload creator
  FetchJobByUuidArgs, //  Argument type of the payload creator
  { rejectValue: FetchJobsError }
>('jobs/fetchJobByUuid', async (uuid: string, { rejectWithValue }) => {
  try {
    const response = await getJobDetailsByUuid(uuid);
    return response;
  } catch (error) {
    // Type guard to narrow down error to Error type
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
    // Fallback for unknown error types
    return rejectWithValue('An unknown error occurred');
  }
});

// Thunk for create a job
export const createJob = createAsyncThunk<
  CreateJobResponse, // Success response type
  CreateJobArgs, // Request payload type
  { rejectValue: { message: string; messages?: Record<string, string[]> } } // Error type for rejection
>(
  'jobs/createJob',
  async (jobData: CreateJobArgs, { dispatch, rejectWithValue }) => {
    try {
      // Call the API function
      const response = await CreateJob(jobData);
      toast.success('Job created successfully!');

      // Optionally fetch updated quotes
      dispatch(fetchJobs());

      // ✅ Broadcast event
      sendBroadcast({ action: 'JOB_ADDED' });

      return response.data; // Return response data
    } catch (error: any) {
      if (error.response) {
        const { message, messages } = error.response.data;
        return rejectWithValue({ message, messages });
      }

      return rejectWithValue({
        message: error.message || 'An unknown error occurred',
      });
    }
  },
);

export const fetchJobNotes = createAsyncThunk<
  FetchJobNoteResponse, // Return type of the payload creator
  FetchJobNotesByUuidArgs, // Argument type of the payload creator
  {
    rejectValue: FetchJobsError; // Type for rejected value
  }
>('jobNotes/fetchQuoteNotes', async (uuid: string, { rejectWithValue }) => {
  try {
    const response = await GetJobNotesData(uuid);
    const data: JobNotes[] = response?.data;
    return { data: data }; // Return formatted data
  } catch (error) {
    // Type guard to narrow down error to Error type
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
    // Fallback for unknown error types
    return rejectWithValue('An unknown error occurred');
  }
});
// Thunk for update job
export const updateJob = createAsyncThunk<
  UpdateJobResponse, // Success response type
  UpdateJobArgs, // Request payload type
  { rejectValue: UpdateJobError } // Error type for rejection
>(
  'jobs/updateJob',
  async (jobData: UpdateJobArgs, { dispatch, rejectWithValue }) => {
    try {
      // Call the API function
      const response = await UpdateJob(jobData);
      toast.success('Job updated successfully!');

      // Optionally fetch updated quotes
      dispatch(fetchJobs());

      // ✅ Broadcast event
      sendBroadcast({ action: 'JOB_UPDATED' });

      return response.data; // Return response data
    } catch (error: any) {
      if (error.response) {
        const { message, messages } = error.response.data;
        return rejectWithValue({ message, messages });
      }

      return rejectWithValue({
        message: error.message || 'An unknown error occurred',
      });
    }
  },
);

export const addJobNote = createAsyncThunk<
  AddJobNoteResponse,
  AddJobNoteArgs,
  {
    rejectValue: FetchJobsError;
  }
>(
  'job/addJobNote',
  async ({ uuid, note, attachment }, { dispatch, rejectWithValue }) => {
    try {
      const response = await AddJobNoteData(uuid, note, attachment);
      const data: AddJobNoteResponse = response;

      dispatch(fetchJobs());
      dispatch(fetchJobNotes(uuid));
      // Ensure you return an object with a 'message' field
      return {
        status: data.status,
        data: data.data,
        message: data.message || 'Note added successfully', // Add the message field
      };
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue({
          message: error.message,
        });
      }
      return rejectWithValue({
        message: 'An unknown error occurred',
      });
    }
  },
);

export const updateJobNote = createAsyncThunk<
  AddJobNoteResponse,
  updateJobNoteArgs,
  {
    rejectValue: FetchJobsError;
  }
>(
  'job/updateJobNote',
  async (
    { uuid, note, attachment, jobUuid, created_at },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await UpdateJobNoteData(
        uuid,
        note,
        attachment,
        jobUuid,
        created_at,
      );
      const data: AddJobNoteResponse = response;

      dispatch(fetchJobs());
      // Ensure you return an object with a 'message' field
      return {
        status: data.status,
        data: data.data,
        message: data.message || 'Note added successfully', // Add the message field
      };
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue({
          message: error.message,
        });
      }
      return rejectWithValue({
        message: 'An unknown error occurred',
      });
    }
  },
);

export const jobSendEmail = createAsyncThunk<
  JobSendEmailResponse, // Return type of the payload creator
  JobSendEmailArgs, // Argument type of the payload creator
  {
    rejectValue: FetchJobsError; // Type for rejected value
  }
>('job/jobSendEmail', async (uuid: string, { rejectWithValue }) => {
  try {
    const response = await JobSendEmail(uuid);
    const data: string = response?.data;
    return { message: data }; // Return formatted data
  } catch (error) {
    // Type guard to narrow down error to Error type
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
    // Fallback for unknown error types
    return rejectWithValue('An unknown error occurred');
  }
});

export const duplicateJob = createAsyncThunk<
  CreateJobResponse, // Success response type
  DuplicateJobArgs, // Request payload type
  { rejectValue: UpdateJobError } // Error type for rejection
>(
  'quote/createQuote',
  async (quoteData: DuplicateJobArgs, { dispatch, rejectWithValue }) => {
    try {
      // Call the API function
      const response = await DuplicateJob(quoteData);
      toast.success('Job duplicated successfully!');

      // Optionally fetch updated quotes
      dispatch(fetchJobs());

      return response.data; // Return response data
    } catch (error: any) {
      if (error.response) {
        const { message, messages } = error.response.data;
        return rejectWithValue({ message, messages });
      }

      return rejectWithValue({
        message: error.message || 'An unknown error occurred',
      });
    }
  },
);

// Thunk for create a job Signature
export const createJobSignature = createAsyncThunk<
  CreateJobResponse, // Success response type
  CreateJobSignatureArgs, // Request payload type
  { rejectValue: { message: string; messages?: Record<string, string[]> } } // Error type for rejection
>(
  'jobs/createJobSignature',
  async (
    jobSignatureData: CreateJobSignatureArgs,
    { dispatch, rejectWithValue },
  ) => {
    try {
      // Call the API function
      const response = await CreateJobSignature(
        jobSignatureData?.uuid,
        jobSignatureData?.payload,
      );
      toast.success(response?.message);
      return response.data; // Return response data
    } catch (error: any) {
      if (error.response) {
        const { message, messages } = error.response.data;
        return rejectWithValue({ message, messages });
      }

      return rejectWithValue({
        message: error.message || 'An unknown error occurred',
      });
    }
  },
);

export const uploadImageToAJob = createAsyncThunk<
  CreateJobResponse, // Success response type
  UploadImageToAJobSArgs, // Request payload type
  { rejectValue: { message: string; messages?: Record<string, string[]> } } // Error type for rejection
>(
  'jobs/uploadImageToAJob',
  async (
    UploadImageData: UploadImageToAJobSArgs,
    { dispatch, rejectWithValue },
  ) => {
    try {
      // Call the API function
      const response = await UploadImageToJob(
        UploadImageData?.uuid,
        UploadImageData?.payload,
      );
      toast.success(response?.message);
      return response.data; // Return response data
    } catch (error: any) {
      if (error.response) {
        const { message, messages } = error.response.data;
        return rejectWithValue({ message, messages });
      }

      return rejectWithValue({
        message: error.message || 'An unknown error occurred',
      });
    }
  },
);

export const uploadHealthAndSafty = createAsyncThunk<
  CreateJobResponse, // Success response type
  UploadHealthSaftyArgs, // Request payload type
  { rejectValue: { message: string; messages?: Record<string, string[]> } } // Error type for rejection
>(
  'jobs/uploadHealthAndSaftyForm',
  async (
    UploadImageData: UploadHealthSaftyArgs,
    { dispatch, rejectWithValue },
  ) => {
    try {
      // Call the API function
      const response = await uploadHealthAndSaftyForm(
        UploadImageData?.uuid,
        UploadImageData?.payload,
      );
      toast.success(response?.message);
      return response.data; // Return response data
    } catch (error: any) {
      if (error.response) {
        const { message, messages } = error.response.data;
        return rejectWithValue({ message, messages });
      }

      return rejectWithValue({
        message: error.message || 'An unknown error occurred',
      });
    }
  },
);

export const deleteJobPhoto = createAsyncThunk<
  void, // Return type of the payload creator
  DeleteJobPhotoArgs, // Argument type of the payload creator
  {
    rejectValue: FetchJobsError; // Type for rejected value
  }
>(
  'jobs/deleteJobPhoto',
  async (
    { jobUuid, jobPhotoUuid }: DeleteJobPhotoArgs,
    { dispatch, rejectWithValue },
  ) => {
    try {
      // Call the API to delete the team member
      await DeleteJobPhotoData(jobUuid, jobPhotoUuid);
      toast.success('Job deleted successfully!');
      // After successful deletion, dispatch fetchTeamMembers to refresh the list
      // dispatch(fetchJobByUuid(jobUuid));
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('An unknown error occurred');
    }
  },
);

export const updateJobStatus = createAsyncThunk<
  UpdateJobResponse, // Success response type
  UpdateJobStatusArgs, // Request payload type
  { rejectValue: UpdateJobError } // Error type for rejection
>(
  'jobs/updateJobStatus',
  async (jobData: UpdateJobStatusArgs, { dispatch, rejectWithValue }) => {
    try {
      // Call the API function
      const response = await UpdateJobStatus(jobData);
      toast.success('Job status updated successfully!');

      // Optionally fetch updated quotes
      dispatch(fetchJobByUuid(jobData.selectedJob));

      return response.data; // Return response data
    } catch (error: any) {
      if (error.response) {
        const { message, messages } = error.response.data;
        return rejectWithValue({ message, messages });
      }

      return rejectWithValue({
        message: error.message || 'An unknown error occurred',
      });
    }
  },
);

// Delete job note
export const deleteJobNote = createAsyncThunk<
  DeleteJobNoteResponse,
  DeleteJobNoteArgs,
  { rejectValue: { message: string; messages?: Record<string, string[]> } }
>(
  'jobs/deleteJobNote',
  async (jobNoteDeleteArgs, { dispatch, rejectWithValue }) => {
    try {
      const response = await DeleteJobNoteData(
        jobNoteDeleteArgs.jobUuid,
        jobNoteDeleteArgs.noteUuid,
      );
      await dispatch(fetchJobNotes(jobNoteDeleteArgs.jobUuid));
      return response;
    } catch (error: any) {
      if (error.response) {
        const { message, messages } = error.response.data;
        return rejectWithValue({ message, messages });
      }
      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }
    }
    return rejectWithValue({ message: 'An unknown error occurred' });
  },
);
