import { AddEnquiryNote } from '@/api/enquiry/enquiryNotes/addEnquiryNote';
import { DeleteEnquiryNote } from '@/api/enquiry/enquiryNotes/deleteEnquiryNote';
import { UpdateEnquiryNote } from '@/api/enquiry/enquiryNotes/updateEnquiryNote';
import { NewEnquiryNoteFormData, NewEnquiryNoteType } from '@/helpers/types';
import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import { GetEnquiryNotes } from '@/api/enquiry/enquiryNotes/getEnquiryNotes';
import { GetEnquiryNote } from '@/api/enquiry/enquiryNotes/getEnquiryNote';

// Types
// Responses
type FetchAllEnquiryNotesResponse = {
  data: NewEnquiryNoteType[];
};

type AddEnquiryNoteResponse = {
  data: NewEnquiryNoteType;
  status: string;
  message: string;
};

type UpdateEnquiryNoteResponse = {
  data: NewEnquiryNoteType;
  status: string;
  message: string;
};

type DeleteEnquiryNoteResponse = {
  status: string;
  message: string;
};

// Arguments
type FetchAllEnquiryNoteArgs = {
  enquiryUuid: string;
};

type FetchSingleEnquiryNoteArgs = {
  enquiryUuid: string;
  noteUuid: string;
};

export interface AddEnquiryNoteData extends NewEnquiryNoteFormData {
  enquiryUuid: string;
}

export interface UpdateEnquiryNoteData extends NewEnquiryNoteFormData {
  enquiryUuid: string;
  noteUuid: string;
}

type DeleteEnquiryNoteArgs = {
  enquiryUuid: string;
  noteUuid: string;
};

type FetchEnquiryNoteError = string | SerializedError;

// Fetch all enquiry notes
export const fetchEnquiryAllNotes = createAsyncThunk<
  FetchAllEnquiryNotesResponse,
  FetchAllEnquiryNoteArgs,
  { rejectValue: FetchEnquiryNoteError }
>(
  'enquiryNote/fetchEnquiryNotes',
  async ({ enquiryUuid }, { rejectWithValue }) => {
    try {
      const response = await GetEnquiryNotes(enquiryUuid);
      return response;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
    }
    return rejectWithValue('An unknown error occurred');
  },
);

// Fetch enquiry single note
export const fetchEnquirySingleNote = createAsyncThunk<
  NewEnquiryNoteType,
  FetchSingleEnquiryNoteArgs,
  { rejectValue: FetchEnquiryNoteError }
>(
  'enquiryNote/fetchEnquirySingleNote',
  async ({ enquiryUuid, noteUuid }, { rejectWithValue }) => {
    try {
      const response = await GetEnquiryNote(enquiryUuid, noteUuid);
      return response.data;
    } catch (error: any) {
      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }
      // if (error.response) {
      //   const { message, messages } = error.response.data;
      //   return rejectWithValue({ message, messages });
      // }
    }
    return rejectWithValue({ message: 'An unknown error occurred' });
  },
);

// Add enquiry note
export const addEnquiryNote = createAsyncThunk<
  AddEnquiryNoteResponse,
  AddEnquiryNoteData,
  { rejectValue: { message: string; messages?: Record<string, string[]> } }
>(
  'enquiryNote/addEnquiryNote',
  async (
    newEnquiryNoteData: AddEnquiryNoteData,
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await AddEnquiryNote(newEnquiryNoteData);
      await dispatch(
        fetchEnquiryAllNotes({ enquiryUuid: newEnquiryNoteData.enquiryUuid }),
      );
      return response;
    } catch (error: any) {
      if (error.response) {
        const { message, messages } = error.response.data;
        return rejectWithValue({ message, messages });
      }
      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }
      return rejectWithValue({ message: 'An unknown error occurred' });
    }
  },
);

// // Update enquiry note
export const updateEnquiryNote = createAsyncThunk<
  UpdateEnquiryNoteResponse,
  UpdateEnquiryNoteData,
  { rejectValue: { message: string; messages?: Record<string, string[]> } }
>(
  'enquiryNote/updateEnquiryNote',
  async (updatedEnquiryNoteData, { dispatch, rejectWithValue }) => {
    try {
      const response = await UpdateEnquiryNote(updatedEnquiryNoteData);
      await dispatch(
        fetchEnquiryAllNotes({
          enquiryUuid: updatedEnquiryNoteData.enquiryUuid
        }),
      );
      return response;
    } catch (error: any) {
      if (error.response) {
        const { message, messages } = error.response.data;
        return rejectWithValue({ message, messages });
      }
      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }
    }
    return rejectWithValue({ message: 'An unknown error occurred' });
  },
);

// Delete enquiry note
export const deleteEnquiryNote = createAsyncThunk<
  DeleteEnquiryNoteResponse,
  DeleteEnquiryNoteArgs,
  { rejectValue: { message: string; messages?: Record<string, string[]> } }
>(
  'deleteNote/deleteEnquiryNote',
  async (DeleteEnquiryNoteArgs, { dispatch, rejectWithValue }) => {
    try {
      const response = await DeleteEnquiryNote(
        DeleteEnquiryNoteArgs.enquiryUuid,
        DeleteEnquiryNoteArgs.noteUuid,
      );
      await dispatch(
        fetchEnquiryAllNotes({
          enquiryUuid: DeleteEnquiryNoteArgs.enquiryUuid,
        }),
      );
      return response;
    } catch (error: any) {
      if (error.response) {
        const { message, messages } = error.response.data;
        return rejectWithValue({ message, messages });
      }
      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }
    }
    return rejectWithValue({ message: 'An unknown error occurred' });
  },
);
