// api/register.ts
import { Method } from 'axios';
import { apiCall } from '@/utility/api/apiUtils';
import { API_JOBS, API_METHOD_GET } from '@/constants/apiConstants';
import { urlEncode } from '@/helpers/common';

export const SearchJobsData = async (
  searchTerm: string,
  pageSize?: string,
  pagination?: number,
  selectedStatus?: string,
  selectedType?: string,
  startDate?: string | null,
  endDate?: string | null,
  selectedCustomer?: string,
  sortBy?: string | null,
  sortOrder?: string | null,
) => {
  const method: Method = API_METHOD_GET;
  let url =
    API_JOBS +
    `?search=${urlEncode(searchTerm)}` +
    (pageSize && `&p-size=${pageSize}`) +
    (pagination && `&p=${pagination}`);

  if (selectedStatus) {
    url += `&f-status=${encodeURIComponent(`${selectedStatus}`)}`;
  }
  if (selectedType) {
    url += `&f-type=${encodeURIComponent(`${selectedType}`)}`;
  }

  if (startDate && endDate) {
    url += `&f-start_date=${encodeURIComponent(`${startDate}:${endDate}`)}`;
    url += `&f-end_date=${encodeURIComponent(`${startDate}:${endDate}`)}`;
  }

  if (selectedCustomer) {
    url += `&f-customer=${encodeURIComponent(`${selectedCustomer}`)}`;
  }

  if (sortBy && sortOrder) {
    url += `&sort-by=${encodeURIComponent(`${sortBy}`)}`;
    url += `&sort-order=${encodeURIComponent(`${sortOrder}`)}`;
  }

  try {
    return await apiCall({
      method,
      url,
    }); // Return the response data for further use
  } catch (error) {
    throw error; // Re-throw the error to be handled by the caller
  }
};
