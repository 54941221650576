import { API_ENQUIRY, API_METHOD_PUT } from '@/constants/apiConstants';
import { UpdateEnquiryNoteData } from '@/redux/thunk/enquiryNoteThunk';
import { apiCall } from '@/utility/api/apiUtils';
import { generateHash } from '@/utility/auth/generateHashValue';
import { Method } from 'axios';

export const UpdateEnquiryNote = async (updatedEnquiryNoteData: UpdateEnquiryNoteData) => {
  const method: Method = API_METHOD_PUT;
  const url =
    API_ENQUIRY +
    '/' +
    updatedEnquiryNoteData.enquiryUuid +
    '/notes/' +
    updatedEnquiryNoteData.noteUuid;
  const data = {
    note: updatedEnquiryNoteData.note,
    attachment: updatedEnquiryNoteData.attachment,
  };

  const jsonString = JSON.stringify(data);
  const additionalHeaders = { hash: generateHash(jsonString) };

  try {
    const response = await apiCall({
      method,
      url,
      data,
      headers: additionalHeaders,
    });
    return response;
  } catch (error) {
    throw error;
  }
};
