import { API_CUSTOMERS, API_METHOD_DELETE } from '@/constants/apiConstants';
import { apiCall } from '@/utility/api/apiUtils';
import { Method } from 'axios';

export const DeleteCustomerNote = async (
  customerUuid: string,
  noteUuid: string,
) => {
  const method: Method = API_METHOD_DELETE;
  const url = API_CUSTOMERS + '/' + customerUuid + '/notes/' + noteUuid;

  try {
    const response = await apiCall({
      method,
      url,
    });
    return response;
  } catch (error) {
    throw error;
  }
};
