import { API_CUSTOMERS, API_METHOD_PUT } from '@/constants/apiConstants';
import { UpdateCustomerNoteData } from '@/redux/thunk/customerNoteThunk';
import { apiCall } from '@/utility/api/apiUtils';
import { generateHash } from '@/utility/auth/generateHashValue';
import { Method } from 'axios';

export const UpdateCustomerNote = async (
  updatedCustomerNoteData: UpdateCustomerNoteData,
) => {
  const method: Method = API_METHOD_PUT;
  const url =
    API_CUSTOMERS +
    '/' +
    updatedCustomerNoteData.customerUuid +
    '/' +
    'notes' +
    '/' +
    updatedCustomerNoteData.noteUuid;

  const data = {
    note: updatedCustomerNoteData.note,
    attachment: updatedCustomerNoteData.attachment,
  };

  const jsonString = JSON.stringify(data);
  const additionalHeaders = { hash: generateHash(jsonString) };

  try {
    const response = await apiCall({
      method,
      url,
      data,
      headers: additionalHeaders,
    });
    return response;
  } catch (error) {
    throw error;
  }
};
