// api/register.ts
import { Method } from 'axios';
import { apiCall } from '@/utility/api/apiUtils';
import { API_JOBS, API_METHOD_DELETE } from '@/constants/apiConstants';

export const DeleteJobNoteData = async (jobUuid: string, noteuUuid: string) => {
  const method: Method = API_METHOD_DELETE;
  const url = API_JOBS + '/' + jobUuid + '/notes/' + noteuUuid;

  try {
    const response = await apiCall({
      method,
      url,
    });

    return response; // Return the response data for further use
  } catch (error) {
    throw error; // Re-throw the error to be handled by the caller
  }
};
