import { CreateInvoiceNote } from '@/api/invoice/notes/createInvoiceNote';
import { DeleteInvoiceNote } from '@/api/invoice/notes/deleteInvoiceNote';
import { GetInvoiceAllNotes } from '@/api/invoice/notes/getInvoiceAllNotes';
import { GetInvoiceSingleNote } from '@/api/invoice/notes/getInvoiceSingleNote';
import { UpdateInvoiceNote } from '@/api/invoice/notes/updateInvoiceNote';
import { InvoiceNoteFormData, InvoiceNoteType } from '@/helpers/types';
import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';

// Types
type FetchAllInvoiceNotesResponse = {
  data: InvoiceNoteType[];
};

type FetchAllInvoiceNoteArgs = {
  invoiceUuid: string;
};

type FetchSingleInvoiceNoteArgs = {
  invoiceUuid: string;
  noteUuid: string;
};

type AddInvoiceNoteResponse = {
  data: InvoiceNoteType;
  status: string;
  message: string;
};

type UpdateInvoiceNoteResponse = {
  data: InvoiceNoteType;
  status: string;
  message: string;
};

export interface AddInvoiceNoteData extends InvoiceNoteFormData {
  invoiceUuid: string;
}

export interface UpdateInvoiceNoteData extends InvoiceNoteFormData {
  invoiceUuid: string;
  noteUuid: string;
}

type DeleteInvoiceNoteResponse = {
  status: string;
  message: string;
};

type DeleteInvoiceNoteArgs = {
  invoiceUuid: string;
  noteUuid: string;
};

type FetchInvoiceNoteError = string | SerializedError;

// Fetch all invoice notes
export const fetchInvoiceAllNotes = createAsyncThunk<
  FetchAllInvoiceNotesResponse,
  FetchAllInvoiceNoteArgs,
  { rejectValue: FetchInvoiceNoteError }
>(
  'invoiceNote/fetchInvoiceNotes',
  async ({ invoiceUuid }, { rejectWithValue }) => {
    try {
      const response = await GetInvoiceAllNotes(invoiceUuid);
      return response;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
    }
    return rejectWithValue('An unknown error occurred');
  },
);

// Fetch invoice single note
export const fetchInvoiceSingleNote = createAsyncThunk<
  InvoiceNoteType,
  FetchSingleInvoiceNoteArgs,
  { rejectValue: FetchInvoiceNoteError }
>(
  'invoiceNote/fetchInvoiceSingleNote',
  async ({ invoiceUuid, noteUuid }, { rejectWithValue }) => {
    try {
      const response = await GetInvoiceSingleNote(invoiceUuid, noteUuid);
      return response.data;
    } catch (error: any) {
      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }
      // if (error.response) {
      //   const { message, messages } = error.response.data;
      //   return rejectWithValue({ message, messages });
      // }
    }
    return rejectWithValue({ message: 'An unknown error occurred' });
  },
);

// Add invoice note
export const addInvoiceNote = createAsyncThunk<
  AddInvoiceNoteResponse,
  AddInvoiceNoteData,
  { rejectValue: { message: string; messages?: Record<string, string[]> } }
>(
  'invoiceNote/addInvoiceNote',
  async (
    newInvoiceNoteData: AddInvoiceNoteData,
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await CreateInvoiceNote(newInvoiceNoteData);
      await dispatch(
        fetchInvoiceAllNotes({ invoiceUuid: newInvoiceNoteData.invoiceUuid }),
      );
      return response;
    } catch (error: any) {
      if (error.response) {
        const { message, messages } = error.response.data;
        return rejectWithValue({ message, messages });
      }
      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }
      return rejectWithValue({ message: 'An unknown error occurred' });
    }
  },
);

// Update invoice note
export const updateInvoiceNote = createAsyncThunk<
  UpdateInvoiceNoteResponse,
  UpdateInvoiceNoteData,
  { rejectValue: { message: string; messages?: Record<string, string[]> } }
>(
  'invoiceNote/updateInvoiceNote',
  async (updatedInvoiceNoteData, { dispatch, rejectWithValue }) => {
    try {
      const response = await UpdateInvoiceNote(updatedInvoiceNoteData);
      await dispatch(
        fetchInvoiceAllNotes({
          invoiceUuid: updatedInvoiceNoteData.invoiceUuid,
        }),
      );
      return response;
    } catch (error: any) {
      if (error.response) {
        const { message, messages } = error.response.data;
        return rejectWithValue({ message, messages });
      }
      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }
    }
    return rejectWithValue({ message: 'An unknown error occurred' });
  },
);

// Delete invoice note
export const deleteInvoiceNote = createAsyncThunk<
  DeleteInvoiceNoteResponse,
  DeleteInvoiceNoteArgs,
  { rejectValue: { message: string; messages?: Record<string, string[]> } }
>(
  'invoiceNote/deleteInvoiceNote',
  async (invoiceNoteDeleteArgs, { dispatch, rejectWithValue }) => {
    try {
      const response = await DeleteInvoiceNote(
        invoiceNoteDeleteArgs.invoiceUuid,
        invoiceNoteDeleteArgs.noteUuid,
      );
      await dispatch(
        fetchInvoiceAllNotes({
          invoiceUuid: invoiceNoteDeleteArgs.invoiceUuid,
        }),
      );
      return response;
    } catch (error: any) {
      if (error.response) {
        const { message, messages } = error.response.data;
        return rejectWithValue({ message, messages });
      }
      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }
    }
    return rejectWithValue({ message: 'An unknown error occurred' });
  },
);
