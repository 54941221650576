import { AddCustomerNoteData } from '@/api/customers/addCustomersNote';
import { CreateCustomerNote } from '@/api/customers/notes/createCustomerNote';
import { DeleteCustomerNote } from '@/api/customers/notes/deleteCustomerNote';
import { GetCustomerAllNotes } from '@/api/customers/notes/getCustomerNote';
import { UpdateCustomerNote } from '@/api/customers/notes/updateCustomerNote';
import { UpdateCustomerNoteData } from '@/api/customers/updateCustomersNote';
import { GetInvoiceSingleNote } from '@/api/invoice/notes/getInvoiceSingleNote';
import {
  CustomerNoteFormData,
  CustomerNoteType,
} from '@/helpers/types';
import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';

// Types
type FetchAllCustomerNotesResponse = {
  data: CustomerNoteType[];
};

type FetchAllCustomerNoteArgs = {
  customerUuid: string;
};

type FetchSingleCustomerNoteArgs = {
  customerUuid: string;
  noteUuid: string;
};

type AddCustomerNoteResponse = {
  data: CustomerNoteType;
  status: string;
  message: string;
};

type UpdateCustomerNoteResponse = {
  data: CustomerNoteType;
  status: string;
  message: string;
};

export interface AddCustomerNoteData extends CustomerNoteFormData {
  customerUuid: string;
}

export interface UpdateCustomerNoteData extends CustomerNoteFormData {
  customerUuid: string;
  noteUuid: string;
}

type DeleteCustomerNoteResponse = {
  status: string;
  message: string;
};

type DeleteCustomerNoteArgs = {
  customerUuid: string;
  noteUuid: string;
};

type FetchCustomerNoteError = string | SerializedError;

// Fetch all customer notes
export const fetchCustomerAllNotes = createAsyncThunk<
  FetchAllCustomerNotesResponse,
  FetchAllCustomerNoteArgs,
  { rejectValue: FetchCustomerNoteError }
>(
  'customerNote/fetchCustomerNotes',
  async ({ customerUuid }, { rejectWithValue }) => {
    try {
      const response = await GetCustomerAllNotes(customerUuid);
      return response;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
    }
    return rejectWithValue('An unknown error occurred');
  },
);

// Fetch customer single note
export const fetchCustomerSingleNote = createAsyncThunk<
  CustomerNoteType,
  FetchSingleCustomerNoteArgs,
  { rejectValue: FetchCustomerNoteError }
>(
  'customerNote/fetchCustomerSingleNote',
  async ({ customerUuid, noteUuid }, { rejectWithValue }) => {
    try {
      const response = await GetInvoiceSingleNote(customerUuid, noteUuid);
      return response.data;
    } catch (error: any) {
      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }
      // if (error.response) {
      //   const { message, messages } = error.response.data;
      //   return rejectWithValue({ message, messages });
      // }
    }
    return rejectWithValue({ message: 'An unknown error occurred' });
  },
);

// Add customer note
export const addCustomerNote = createAsyncThunk<
  AddCustomerNoteResponse,
  AddCustomerNoteData,
  { rejectValue: { message: string; messages?: Record<string, string[]> } }
>(
  'customerNote/addCustomerNote',
  async (
    newCustomerNoteData: AddCustomerNoteData,
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await CreateCustomerNote(newCustomerNoteData);
      await dispatch(
        fetchCustomerAllNotes({
          customerUuid: newCustomerNoteData.customerUuid,
        }),
      );
      return response;
    } catch (error: any) {
      if (error.response) {
        const { message, messages } = error.response.data;
        return rejectWithValue({ message, messages });
      }
      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }
      return rejectWithValue({ message: 'An unknown error occurred' });
    }
  },
);

// Update customer note
export const updateCustomerNote = createAsyncThunk<
  UpdateCustomerNoteResponse,
  UpdateCustomerNoteData,
  { rejectValue: { message: string; messages?: Record<string, string[]> } }
>(
  'customerNote/updateCustomerNote',
  async (UpdateCustomerNoteData, { dispatch, rejectWithValue }) => {
    try {
      const response = await UpdateCustomerNote(UpdateCustomerNoteData);
      await dispatch(
        fetchCustomerAllNotes({
          customerUuid: UpdateCustomerNoteData.customerUuid,
        }),
      );
      return response;
    } catch (error: any) {
      if (error.response) {
        const { message, messages } = error.response.data;
        return rejectWithValue({ message, messages });
      }
      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }
    }
    return rejectWithValue({ message: 'An unknown error occurred' });
  },
);

// Delete customer note
export const deleteCustomerNote = createAsyncThunk<
  DeleteCustomerNoteResponse,
  DeleteCustomerNoteArgs,
  { rejectValue: { message: string; messages?: Record<string, string[]> } }
>(
  'customerNote/deleteCustomerNote',
  async (customerNoteDeleteArgs, { dispatch, rejectWithValue }) => {
    try {
      const response = await DeleteCustomerNote(
        customerNoteDeleteArgs.customerUuid,
        customerNoteDeleteArgs.noteUuid,
      );
      await dispatch(
        fetchCustomerAllNotes({
          customerUuid: customerNoteDeleteArgs.customerUuid,
        }),
      );
      return response;
    } catch (error: any) {
      if (error.response) {
        const { message, messages } = error.response.data;
        return rejectWithValue({ message, messages });
      }
      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }
    }
    return rejectWithValue({ message: 'An unknown error occurred' });
  },
);
