// api/register.ts
import { Method } from 'axios';
import { apiCall } from '@/utility/api/apiUtils';
import { API_JOBS, API_METHOD_PUT } from '@/constants/apiConstants';
import { generateHash } from '@/utility/auth/generateHashValue';

export const UpdateJobNoteData = async (
  uuid: string,
  note: string,
  attachment: string,
  jobUuid: string,
  created_at: string,
) => {
  const method: Method = API_METHOD_PUT;

  const url = API_JOBS + `/${uuid}/notes/` + jobUuid;

  const data = {
    note: note,
    attachment: attachment,
    // created_at: created_at,
  };

  // Convert JSON object to string
  const jsonString = JSON.stringify(data);
  const additionalHeaders = {
    hash: generateHash(jsonString),
  };
  try {
    const response = await apiCall({
      method,
      url,
      data,
      headers: additionalHeaders,
    });
    return response; // Return the response data for further use
  } catch (error) {
    throw error; // Re-throw the error to be handled by the caller
  }
};
