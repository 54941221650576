import { CreateSupplierNote } from '@/api/supplier/notes/createSupplierNote';
import { DeleteSupplierNote } from '@/api/supplier/notes/deleteSupplierNote';
import { GetSupplierAllNotes } from '@/api/supplier/notes/getSupplierAllNotes';
import { GetSupplierSingleNote } from '@/api/supplier/notes/getSupplierSingleNote';
import { UpdateSupplierNote } from '@/api/supplier/notes/updateSupplierNote';
import { InvoiceNoteFormData, SupplierNoteType } from '@/helpers/types';
import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';

// Types
// Type of responses
type FetchAllSupplierNotesResponse = {
  data: SupplierNoteType[];
};

type AddSupplierNoteResponse = {
  data: SupplierNoteType;
  status: string;
  message: string;
};

type DeleteSupplierNoteResponse = {
  status: string;
};

type UpdateSupplierNoteResponse = {
  data: SupplierNoteType;
  status: string;
  message: string;
};

export interface AddSupplierNoteData extends InvoiceNoteFormData {
  supplierUuid: string;
}

export interface UpdateSupplierNoteData extends InvoiceNoteFormData {
  supplierUuid: string;
  noteUuid: string;
}

// Type of arguments
type FetchAllSupplierNoteArgs = {
  supplierUuid: string;
};

type DeleteSupplierNoteArgs = {
  supplierUuid: string;
  noteUuid: string;
};

type FetchSingleSupplierNoteArgs = {
  supplierUuid: string;
  noteUuid: string;
};

type FetchSupplierNoteError = string | SerializedError;

// Fetch all invoice notes
export const fetchSupplierAllNotes = createAsyncThunk<
  FetchAllSupplierNotesResponse,
  FetchAllSupplierNoteArgs,
  { rejectValue: FetchSupplierNoteError }
>(
  'supplierNote/fetchSupplierNotes',
  async ({ supplierUuid }, { rejectWithValue }) => {
    try {
      const response = await GetSupplierAllNotes(supplierUuid);
      return response;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
    }
    return rejectWithValue('An unknown error occurred');
  },
);

// Fetch supplier single note
export const fetchSupplierSingleNote = createAsyncThunk<
  SupplierNoteType,
  FetchSingleSupplierNoteArgs,
  { rejectValue: FetchSupplierNoteError }
>(
  'supplierNote/fetchSupplierSingleNote',
  async ({ supplierUuid, noteUuid }, { rejectWithValue }) => {
    try {
      const response = await GetSupplierSingleNote(supplierUuid, noteUuid);
      return response.data;
    } catch (error: any) {
      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }
      // if (error.response) {
      //   const { message, messages } = error.response.data;
      //   return rejectWithValue({ message, messages });
      // }
    }
    return rejectWithValue({ message: 'An unknown error occurred' });
  },
);

// Add supplier note
export const addSupplierNote = createAsyncThunk<
  AddSupplierNoteResponse,
  AddSupplierNoteData,
  { rejectValue: { message: string; messages?: Record<string, string[]> } }
>(
  'supplierNote/addSupplierNote',
  async (
    newSupplierNoteData: AddSupplierNoteData,
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await CreateSupplierNote(newSupplierNoteData);
      await dispatch(
        fetchSupplierAllNotes({
          supplierUuid: newSupplierNoteData.supplierUuid,
        }),
      );
      return response;
    } catch (error: any) {
      if (error.response) {
        const { message, messages } = error.response.data;
        return rejectWithValue({ message, messages });
      }
      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }
      return rejectWithValue({ message: 'An unknown error occurred' });
    }
  },
);

// Update supplier note
export const updateSupplierNote = createAsyncThunk<
  UpdateSupplierNoteResponse,
  UpdateSupplierNoteData,
  { rejectValue: { message: string; messages?: Record<string, string[]> } }
>(
  'supplierNote/supplierInvoiceNote',
  async (updatedSupplierNoteData, { dispatch, rejectWithValue }) => {
    try {
      const response = await UpdateSupplierNote(updatedSupplierNoteData);
      await dispatch(
        fetchSupplierAllNotes({
          supplierUuid: updatedSupplierNoteData.supplierUuid,
        }),
      );
      return response;
    } catch (error: any) {
      if (error.response) {
        const { message, messages } = error.response.data;
        return rejectWithValue({ message, messages });
      }
      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }
    }
    return rejectWithValue({ message: 'An unknown error occurred' });
  },
);

// Delete supplier note
export const deleteSupplierNote = createAsyncThunk<
  DeleteSupplierNoteResponse,
  DeleteSupplierNoteArgs,
  { rejectValue: { message: string; messages?: Record<string, string[]> } }
>(
  'supplierNote/deleteSupplierNote',
  async (supplierNoteDeleteArgs, { dispatch, rejectWithValue }) => {
    try {
      const response = await DeleteSupplierNote(
        supplierNoteDeleteArgs.supplierUuid,
        supplierNoteDeleteArgs.noteUuid,
      );
      await dispatch(
        fetchSupplierAllNotes({
          supplierUuid: supplierNoteDeleteArgs.supplierUuid,
        }),
      );
      return response;
    } catch (error: any) {
      if (error.response) {
        const { message, messages } = error.response.data;
        return rejectWithValue({ message, messages });
      }
      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }
    }
    return rejectWithValue({ message: 'An unknown error occurred' });
  },
);
